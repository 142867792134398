export const WAlert = () => import('../../node_modules/@wellcare/vue-component/components/global/alert.vue' /* webpackChunkName: "components/w-alert" */).then(c => wrapFunctional(c.default || c))
export const WBottomMenu = () => import('../../node_modules/@wellcare/vue-component/components/global/bottom-menu.vue' /* webpackChunkName: "components/w-bottom-menu" */).then(c => wrapFunctional(c.default || c))
export const WBottomSheetWrapper = () => import('../../node_modules/@wellcare/vue-component/components/global/bottom-sheet-wrapper.vue' /* webpackChunkName: "components/w-bottom-sheet-wrapper" */).then(c => wrapFunctional(c.default || c))
export const WButton = () => import('../../node_modules/@wellcare/vue-component/components/global/button.vue' /* webpackChunkName: "components/w-button" */).then(c => wrapFunctional(c.default || c))
export const WChipsField = () => import('../../node_modules/@wellcare/vue-component/components/global/chips-field.vue' /* webpackChunkName: "components/w-chips-field" */).then(c => wrapFunctional(c.default || c))
export const WContentPage = () => import('../../node_modules/@wellcare/vue-component/components/global/content-page.vue' /* webpackChunkName: "components/w-content-page" */).then(c => wrapFunctional(c.default || c))
export const WContextMenu = () => import('../../node_modules/@wellcare/vue-component/components/global/context-menu.vue' /* webpackChunkName: "components/w-context-menu" */).then(c => wrapFunctional(c.default || c))
export const WDataList = () => import('../../node_modules/@wellcare/vue-component/components/global/data-list.vue' /* webpackChunkName: "components/w-data-list" */).then(c => wrapFunctional(c.default || c))
export const WDateTimePicker = () => import('../../node_modules/@wellcare/vue-component/components/global/date-time-picker.vue' /* webpackChunkName: "components/w-date-time-picker" */).then(c => wrapFunctional(c.default || c))
export const WDebugLog = () => import('../../node_modules/@wellcare/vue-component/components/global/debug-log.vue' /* webpackChunkName: "components/w-debug-log" */).then(c => wrapFunctional(c.default || c))
export const WDevice = () => import('../../node_modules/@wellcare/vue-component/components/global/device.vue' /* webpackChunkName: "components/w-device" */).then(c => wrapFunctional(c.default || c))
export const WDialogConfirm = () => import('../../node_modules/@wellcare/vue-component/components/global/dialog-confirm.vue' /* webpackChunkName: "components/w-dialog-confirm" */).then(c => wrapFunctional(c.default || c))
export const WDialog = () => import('../../node_modules/@wellcare/vue-component/components/global/dialog.vue' /* webpackChunkName: "components/w-dialog" */).then(c => wrapFunctional(c.default || c))
export const WDynamicForm = () => import('../../node_modules/@wellcare/vue-component/components/global/dynamic-form.vue' /* webpackChunkName: "components/w-dynamic-form" */).then(c => wrapFunctional(c.default || c))
export const WFieldDatePicker = () => import('../../node_modules/@wellcare/vue-component/components/global/field-date-picker.vue' /* webpackChunkName: "components/w-field-date-picker" */).then(c => wrapFunctional(c.default || c))
export const WJsonEditor = () => import('../../node_modules/@wellcare/vue-component/components/global/json-editor.vue' /* webpackChunkName: "components/w-json-editor" */).then(c => wrapFunctional(c.default || c))
export const WLoading = () => import('../../node_modules/@wellcare/vue-component/components/global/loading.vue' /* webpackChunkName: "components/w-loading" */).then(c => wrapFunctional(c.default || c))
export const WNumberInput = () => import('../../node_modules/@wellcare/vue-component/components/global/number-input.vue' /* webpackChunkName: "components/w-number-input" */).then(c => wrapFunctional(c.default || c))
export const WPermissionsProvider = () => import('../../node_modules/@wellcare/vue-component/components/global/permissions-provider.vue' /* webpackChunkName: "components/w-permissions-provider" */).then(c => wrapFunctional(c.default || c))
export const WProgressStepper = () => import('../../node_modules/@wellcare/vue-component/components/global/progress-stepper.vue' /* webpackChunkName: "components/w-progress-stepper" */).then(c => wrapFunctional(c.default || c))
export const WProvincePicker = () => import('../../node_modules/@wellcare/vue-component/components/global/province-picker.vue' /* webpackChunkName: "components/w-province-picker" */).then(c => wrapFunctional(c.default || c))
export const WSearchInput = () => import('../../node_modules/@wellcare/vue-component/components/global/search-input.vue' /* webpackChunkName: "components/w-search-input" */).then(c => wrapFunctional(c.default || c))
export const WSearchSelect = () => import('../../node_modules/@wellcare/vue-component/components/global/search-select.vue' /* webpackChunkName: "components/w-search-select" */).then(c => wrapFunctional(c.default || c))
export const WSection = () => import('../../node_modules/@wellcare/vue-component/components/global/section.vue' /* webpackChunkName: "components/w-section" */).then(c => wrapFunctional(c.default || c))
export const WSegmented = () => import('../../node_modules/@wellcare/vue-component/components/global/segmented.vue' /* webpackChunkName: "components/w-segmented" */).then(c => wrapFunctional(c.default || c))
export const WSelectLocale = () => import('../../node_modules/@wellcare/vue-component/components/global/select-locale.vue' /* webpackChunkName: "components/w-select-locale" */).then(c => wrapFunctional(c.default || c))
export const WSelectableButton = () => import('../../node_modules/@wellcare/vue-component/components/global/selectable-button.vue' /* webpackChunkName: "components/w-selectable-button" */).then(c => wrapFunctional(c.default || c))
export const WSuperPanel = () => import('../../node_modules/@wellcare/vue-component/components/global/super-panel.vue' /* webpackChunkName: "components/w-super-panel" */).then(c => wrapFunctional(c.default || c))
export const WSwipeableBottomSheet = () => import('../../node_modules/@wellcare/vue-component/components/global/swipeable-bottom-sheet.vue' /* webpackChunkName: "components/w-swipeable-bottom-sheet" */).then(c => wrapFunctional(c.default || c))
export const WSwipeableLayout = () => import('../../node_modules/@wellcare/vue-component/components/global/swipeable-layout.vue' /* webpackChunkName: "components/w-swipeable-layout" */).then(c => wrapFunctional(c.default || c))
export const WSwitchUser = () => import('../../node_modules/@wellcare/vue-component/components/global/switch-user.vue' /* webpackChunkName: "components/w-switch-user" */).then(c => wrapFunctional(c.default || c))
export const WTextFieldCopy = () => import('../../node_modules/@wellcare/vue-component/components/global/text-field-copy.vue' /* webpackChunkName: "components/w-text-field-copy" */).then(c => wrapFunctional(c.default || c))
export const WTextareaCopy = () => import('../../node_modules/@wellcare/vue-component/components/global/textarea-copy.vue' /* webpackChunkName: "components/w-textarea-copy" */).then(c => wrapFunctional(c.default || c))
export const WTimePicker = () => import('../../node_modules/@wellcare/vue-component/components/global/time-picker.vue' /* webpackChunkName: "components/w-time-picker" */).then(c => wrapFunctional(c.default || c))
export const WUserProfileForm = () => import('../../node_modules/@wellcare/vue-component/components/global/user-profile-form.vue' /* webpackChunkName: "components/w-user-profile-form" */).then(c => wrapFunctional(c.default || c))
export const WYearPicker = () => import('../../node_modules/@wellcare/vue-component/components/global/year-picker.vue' /* webpackChunkName: "components/w-year-picker" */).then(c => wrapFunctional(c.default || c))
export const WButtonRefresh = () => import('../../node_modules/@wellcare/vue-component/components/global/button/refresh.vue' /* webpackChunkName: "components/w-button-refresh" */).then(c => wrapFunctional(c.default || c))
export const WCardError = () => import('../../node_modules/@wellcare/vue-component/components/global/card/error.vue' /* webpackChunkName: "components/w-card-error" */).then(c => wrapFunctional(c.default || c))
export const WCard = () => import('../../node_modules/@wellcare/vue-component/components/global/card/index.vue' /* webpackChunkName: "components/w-card" */).then(c => wrapFunctional(c.default || c))
export const WDrawerFooter = () => import('../../node_modules/@wellcare/vue-component/components/global/drawer/drawer-footer.vue' /* webpackChunkName: "components/w-drawer-footer" */).then(c => wrapFunctional(c.default || c))
export const WDrawerGroupItem = () => import('../../node_modules/@wellcare/vue-component/components/global/drawer/drawer-group-item.vue' /* webpackChunkName: "components/w-drawer-group-item" */).then(c => wrapFunctional(c.default || c))
export const WDrawerHeader = () => import('../../node_modules/@wellcare/vue-component/components/global/drawer/drawer-header.vue' /* webpackChunkName: "components/w-drawer-header" */).then(c => wrapFunctional(c.default || c))
export const WDrawerSupport = () => import('../../node_modules/@wellcare/vue-component/components/global/drawer/drawer-support.vue' /* webpackChunkName: "components/w-drawer-support" */).then(c => wrapFunctional(c.default || c))
export const WDrawer = () => import('../../node_modules/@wellcare/vue-component/components/global/drawer/drawer.vue' /* webpackChunkName: "components/w-drawer" */).then(c => wrapFunctional(c.default || c))
export const WKanbanCardDetail = () => import('../../node_modules/@wellcare/vue-component/components/global/kanban/card-detail.vue' /* webpackChunkName: "components/w-kanban-card-detail" */).then(c => wrapFunctional(c.default || c))
export const WKanbanCard = () => import('../../node_modules/@wellcare/vue-component/components/global/kanban/card.vue' /* webpackChunkName: "components/w-kanban-card" */).then(c => wrapFunctional(c.default || c))
export const WKanban = () => import('../../node_modules/@wellcare/vue-component/components/global/kanban/index.vue' /* webpackChunkName: "components/w-kanban" */).then(c => wrapFunctional(c.default || c))
export const WKanbanList = () => import('../../node_modules/@wellcare/vue-component/components/global/kanban/list.vue' /* webpackChunkName: "components/w-kanban-list" */).then(c => wrapFunctional(c.default || c))
export const WMobileAutocomplete = () => import('../../node_modules/@wellcare/vue-component/components/global/mobile/autocomplete.vue' /* webpackChunkName: "components/w-mobile-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const WOnboardingGroup = () => import('../../node_modules/@wellcare/vue-component/components/global/onboarding/onboarding-group.vue' /* webpackChunkName: "components/w-onboarding-group" */).then(c => wrapFunctional(c.default || c))
export const WOnboardingItem = () => import('../../node_modules/@wellcare/vue-component/components/global/onboarding/onboarding-item.vue' /* webpackChunkName: "components/w-onboarding-item" */).then(c => wrapFunctional(c.default || c))
export const WPageAdminTranslation = () => import('../../node_modules/@wellcare/vue-component/components/global/page/admin-translation.vue' /* webpackChunkName: "components/w-page-admin-translation" */).then(c => wrapFunctional(c.default || c))
export const WPageDebug = () => import('../../node_modules/@wellcare/vue-component/components/global/page/debug.vue' /* webpackChunkName: "components/w-page-debug" */).then(c => wrapFunctional(c.default || c))
export const WPageThemePage = () => import('../../node_modules/@wellcare/vue-component/components/global/page/theme-page.vue' /* webpackChunkName: "components/w-page-theme-page" */).then(c => wrapFunctional(c.default || c))
export const WPermissions = () => import('../../node_modules/@wellcare/vue-component/components/global/permissions/index.vue' /* webpackChunkName: "components/w-permissions" */).then(c => wrapFunctional(c.default || c))
export const WPickerDateTimeRange = () => import('../../node_modules/@wellcare/vue-component/components/global/picker/date-time-range.vue' /* webpackChunkName: "components/w-picker-date-time-range" */).then(c => wrapFunctional(c.default || c))
export const WPickerDateTime = () => import('../../node_modules/@wellcare/vue-component/components/global/picker/date-time.vue' /* webpackChunkName: "components/w-picker-date-time" */).then(c => wrapFunctional(c.default || c))
export const WPickerDateV2 = () => import('../../node_modules/@wellcare/vue-component/components/global/picker/date-v2.vue' /* webpackChunkName: "components/w-picker-date-v2" */).then(c => wrapFunctional(c.default || c))
export const WPickerDate = () => import('../../node_modules/@wellcare/vue-component/components/global/picker/date.vue' /* webpackChunkName: "components/w-picker-date" */).then(c => wrapFunctional(c.default || c))
export const WPickerProvince = () => import('../../node_modules/@wellcare/vue-component/components/global/picker/province.vue' /* webpackChunkName: "components/w-picker-province" */).then(c => wrapFunctional(c.default || c))
export const WPickerScrollWheel = () => import('../../node_modules/@wellcare/vue-component/components/global/picker/scroll-wheel.vue' /* webpackChunkName: "components/w-picker-scroll-wheel" */).then(c => wrapFunctional(c.default || c))
export const WPickerTimeRange = () => import('../../node_modules/@wellcare/vue-component/components/global/picker/time-range.vue' /* webpackChunkName: "components/w-picker-time-range" */).then(c => wrapFunctional(c.default || c))
export const WPickerTime = () => import('../../node_modules/@wellcare/vue-component/components/global/picker/time.vue' /* webpackChunkName: "components/w-picker-time" */).then(c => wrapFunctional(c.default || c))
export const WPickerYear = () => import('../../node_modules/@wellcare/vue-component/components/global/picker/year.vue' /* webpackChunkName: "components/w-picker-year" */).then(c => wrapFunctional(c.default || c))
export const WVideoKit = () => import('../../node_modules/@wellcare/vue-component/components/global/video/kit.vue' /* webpackChunkName: "components/w-video-kit" */).then(c => wrapFunctional(c.default || c))
export const WVideoWaitingRoom = () => import('../../node_modules/@wellcare/vue-component/components/global/video/waiting-room.vue' /* webpackChunkName: "components/w-video-waiting-room" */).then(c => wrapFunctional(c.default || c))
export const WAudioDisplay = () => import('../../node_modules/@wellcare/nuxt-module-media/components/global/audio-display.vue' /* webpackChunkName: "components/w-audio-display" */).then(c => wrapFunctional(c.default || c))
export const WAudioPlayer = () => import('../../node_modules/@wellcare/nuxt-module-media/components/global/audio-player.vue' /* webpackChunkName: "components/w-audio-player" */).then(c => wrapFunctional(c.default || c))
export const WAudioRecorder = () => import('../../node_modules/@wellcare/nuxt-module-media/components/global/audio-recorder.vue' /* webpackChunkName: "components/w-audio-recorder" */).then(c => wrapFunctional(c.default || c))
export const WAvatar = () => import('../../node_modules/@wellcare/nuxt-module-media/components/global/avatar.vue' /* webpackChunkName: "components/w-avatar" */).then(c => wrapFunctional(c.default || c))
export const WFileInput = () => import('../../node_modules/@wellcare/nuxt-module-media/components/global/file-input.vue' /* webpackChunkName: "components/w-file-input" */).then(c => wrapFunctional(c.default || c))
export const WGallery = () => import('../../node_modules/@wellcare/nuxt-module-media/components/global/gallery.vue' /* webpackChunkName: "components/w-gallery" */).then(c => wrapFunctional(c.default || c))
export const WGroupActionBtn = () => import('../../node_modules/@wellcare/nuxt-module-media/components/global/group-action-btn.vue' /* webpackChunkName: "components/w-group-action-btn" */).then(c => wrapFunctional(c.default || c))
export const WImageVideoUpload = () => import('../../node_modules/@wellcare/nuxt-module-media/components/global/image-video-upload.vue' /* webpackChunkName: "components/w-image-video-upload" */).then(c => wrapFunctional(c.default || c))
export const WImg = () => import('../../node_modules/@wellcare/nuxt-module-media/components/global/img.vue' /* webpackChunkName: "components/w-img" */).then(c => wrapFunctional(c.default || c))
export const WTimePointSelector = () => import('../../node_modules/@wellcare/nuxt-module-media/components/global/time-point-selector.vue' /* webpackChunkName: "components/w-time-point-selector" */).then(c => wrapFunctional(c.default || c))
export const WVideoDisplay = () => import('../../node_modules/@wellcare/nuxt-module-media/components/global/video-display.vue' /* webpackChunkName: "components/w-video-display" */).then(c => wrapFunctional(c.default || c))
export const WVideoOverview = () => import('../../node_modules/@wellcare/nuxt-module-media/components/global/video-overview.vue' /* webpackChunkName: "components/w-video-overview" */).then(c => wrapFunctional(c.default || c))
export const WVideoPlayer = () => import('../../node_modules/@wellcare/nuxt-module-media/components/global/video-player.vue' /* webpackChunkName: "components/w-video-player" */).then(c => wrapFunctional(c.default || c))
export const WVideoThumbnail = () => import('../../node_modules/@wellcare/nuxt-module-media/components/global/video-thumbnail.vue' /* webpackChunkName: "components/w-video-thumbnail" */).then(c => wrapFunctional(c.default || c))
export const WEduHubChannel = () => import('../../node_modules/@wellcare/nuxt-module-media/components/global/edu-hub/channel.vue' /* webpackChunkName: "components/w-edu-hub-channel" */).then(c => wrapFunctional(c.default || c))
export const WEduHubPage = () => import('../../node_modules/@wellcare/nuxt-module-media/components/global/edu-hub/page.vue' /* webpackChunkName: "components/w-edu-hub-page" */).then(c => wrapFunctional(c.default || c))
export const WEduHubPlaylistFile = () => import('../../node_modules/@wellcare/nuxt-module-media/components/global/edu-hub/playlist-file.vue' /* webpackChunkName: "components/w-edu-hub-playlist-file" */).then(c => wrapFunctional(c.default || c))
export const WEduHubPlaylist = () => import('../../node_modules/@wellcare/nuxt-module-media/components/global/edu-hub/playlist.vue' /* webpackChunkName: "components/w-edu-hub-playlist" */).then(c => wrapFunctional(c.default || c))
export const FileItem = () => import('../../src/components/file-item.vue' /* webpackChunkName: "components/file-item" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
