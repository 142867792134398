import { mdiCloudUploadOutline, mdiDelete, mdiMagnify, mdiPencil, mdiShare, mdiClose, mdiMicrophone, mdiStop, mdiContentSave, mdiCalendar, mdiImage, mdiPlay, mdiEye, mdiTrashCan, mdiAttachment, mdiDownload, mdiDotsHorizontal, mdiClock, mdiWaveform, mdiDotsVertical, mdiChevronDoubleDown, mdiChevronDoubleUp, mdiPlus, mdiCameraEnhance, mdiCheck, mdiPause, mdiShareOff, mdiImageEdit, mdiAccount, mdiCameraOutline, mdiBookmark, mdiBellOutline, mdiCommentQuestion, mdiStethoscope, mdiContentCopy } from '@mdi/js';
import colors from 'vuetify/lib/util/colors';
export default {
    icons: {
        iconfont: 'mdiSvg',
        values: {
            cameraOutline: mdiCameraOutline,
            cloudUpload: mdiCloudUploadOutline,
            delete: mdiDelete,
            magnify: mdiMagnify,
            share: mdiShare,
            edit: mdiPencil,
            close: mdiClose,
            microphone: mdiMicrophone,
            stop: mdiStop,
            plus: mdiPlus,
            save: mdiContentSave,
            calendar: mdiCalendar,
            image: mdiImage,
            play: mdiPlay,
            eye: mdiEye,
            trash: mdiTrashCan,
            attachment: mdiAttachment,
            download: mdiDownload,
            dotsHorizontal: mdiDotsHorizontal,
            clock: mdiClock,
            waveform: mdiWaveform,
            dotsVertical: mdiDotsVertical,
            doubleDown: mdiChevronDoubleDown,
            doubleUp: mdiChevronDoubleUp,
            cameraEnhance: mdiCameraEnhance,
            check: mdiCheck,
            pause: mdiPause,
            shareOff: mdiShareOff,
            imageEdit: mdiImageEdit,
            account: mdiAccount,
            bookmark: mdiBookmark,
            bellOutline: mdiBellOutline,
            commentQuestion: mdiCommentQuestion,
            stethoscope: mdiStethoscope,
            contentCopy: mdiContentCopy
        }
    },
    theme: {
        themes: {
            dark: {
                primary: colors.blue.darken2,
                accent: colors.grey.darken3,
                secondary: colors.amber.darken3,
                info: colors.teal.lighten1,
                warning: colors.amber.base,
                error: colors.deepOrange.accent4,
                success: colors.green.accent3
            },
            light: {
                primary: '#1976D2',
                secondary: '#424242',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107'
            }
        },
        options: {
            customProperties: true
        }
    }
};
