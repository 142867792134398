"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.actions = exports.mutations = exports.getters = exports.state = exports.namespaced = exports.strict = void 0;
const typed_vuex_1 = require("typed-vuex");
const vuex_composition_map_fields_1 = require("vuex-composition-map-fields");
const vue_component_1 = require("@wellcare/vue-component");
const namespace = 'nuxt-module-data-layer';
const { remove, set } = (0, vue_component_1.usePreferences)();
exports.strict = false;
exports.namespaced = true;
const state = () => ({
    user: null,
    token: ''
});
exports.state = state;
exports.getters = (0, typed_vuex_1.getterTree)(exports.state, {
    getField: vuex_composition_map_fields_1.getField
});
exports.mutations = (0, typed_vuex_1.mutationTree)(exports.state, {
    updateField: vuex_composition_map_fields_1.updateField,
    SET_USER(state, user) {
        state.user = {
            ...user,
            ...user === null || user === void 0 ? void 0 : user.provider,
            _id: user === null || user === void 0 ? void 0 : user._id
        };
    },
    SET_TOKEN(state, token) {
        state.token = token;
    },
    REMOVE_USER(state) {
        state.user = null;
        state.token = null;
    },
    SET_MEMBERSHIP(state, { isMember, membership }) {
        state.user.isMember = isMember;
        state.user.membership = membership;
    },
    UPDATE_ISMEMBER(state, isMember) {
        state.user.isMember = isMember;
    }
});
exports.actions = (0, typed_vuex_1.actionTree)({ state: exports.state, getters: exports.getters, mutations: exports.mutations }, {
    setToken: async ({ commit }, { ctx: { $axios, app }, data: { accessToken, refreshToken } }) => {
        const axios = $axios || app.$axios;
        const cookies = app.$cookies;
        // Set the new access token and refresh token
        axios.setHeader('token', accessToken);
        cookies.set('token', accessToken); // DEPRECATED
        commit('SET_TOKEN', accessToken);
        await set({
            key: 'token',
            value: accessToken
        });
        if (refreshToken) {
            await set({
                key: 'refreshToken',
                value: refreshToken
            });
        }
    },
    setUser: async ({ commit }, { data: { user } }) => {
        // Update the authentication state in the store
        commit('SET_USER', user);
        // Set the and user data in preferences\
        await set({
            key: 'user',
            value: JSON.stringify({
                _id: user === null || user === void 0 ? void 0 : user._id,
                phone: user === null || user === void 0 ? void 0 : user.phone,
                countryCode: user === null || user === void 0 ? void 0 : user.countryCode,
                avatar: user === null || user === void 0 ? void 0 : user.avatar,
                name: user === null || user === void 0 ? void 0 : user.name
            })
        });
    },
    redirectToSignIn: async (_store, { ctx: { $config } }) => {
        if (!process.client)
            return; // Only execute on the client-side
        const config = $config[namespace];
        const redirectUrl = btoa(location.href); // Encode the current URL
        const accountBaseUrl = config === null || config === void 0 ? void 0 : config.accountBaseURL;
        // Remove token and refreshToken
        await remove({ key: 'token' });
        await remove({ key: 'refreshToken' });
        // If the authentication module is not enabled
        window.location.href = `${accountBaseUrl}?redirect=${redirectUrl}`;
    },
    signOut: async (_store, { ctx: { $config } }) => {
        const accountBaseURL = $config === null || $config === void 0 ? void 0 : $config.accountBaseUrl;
        await remove({ key: 'token' });
        await remove({ key: 'refreshToken' });
        localStorage.removeItem('axios');
        const originUrl = btoa(new URL(location.href).origin);
        window.location.href = `${accountBaseURL}/auth/sign-out/landing?redirect=${originUrl}`;
    }
});
