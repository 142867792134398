import Vue from 'vue'
import Toasted from 'vue-toasted'

Vue.use(Toasted, {"position":"top-center","singleton":true,"keepOnHover":true,"theme":"outline","icon":false,"closeOnSwipe":true,"className":"w-toast"})

const globals = [{"name":"appError","message":(payload) => {
      // if there is a message show it with the message
      return payload?.message || 'Đã có lỗi xảy ra';
    },"options":{"type":"error","duration":2500}},{"name":"appWarning","message":(payload) => {
      // if there is a message show it with the message
      return payload?.message || 'Đã có lỗi xảy ra';
    },"options":{"type":"warning","duration":1500,"className":["w-toast","w-warning-toast"]}},{"name":"appInfo","message":(payload) => {
      // if there is a message show it with the message
      return payload?.message || 'Đã có lỗi xảy ra';
    },"options":{"type":"success","duration":2500,"className":["w-toast","w-info-toast"]}}]
if(globals) {
  globals.forEach(global => {
    Vue.toasted.register(global.name, global.message, global.options)
  })
}

export default function (ctx, inject) {
  inject('toast', Vue.toasted)
}
